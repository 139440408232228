import React, { Component } from 'react';
import { navigateTo } from 'gatsby-link';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import SEO from '../components/seo';
import '../utils/css/fileroom.css';
import fileroomLogo from '../images/file_room.png';
import whitlockLogo from '../images/logo_1.png';

import { GoogleLogin } from 'react-google-login';

import TextBox from '../components/FormElements/textBox';
class FileRoom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userName: '',
			password: ''
		};
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	onSubmit = (e) => {
		e.preventDefault();
		const { userName, password } = this.state;
		if (userName !== '' && password !== '') {
			navigateTo('/dashboard');
		}
	};

	responseGoogle = (res) => {
		console.log('GOOGLE', res);
	};
	render() {
		const { userName, password } = this.state;

		// console.log('what happ...');
		// wasabiActions({ action: 'getAll', bucket: 'anjaneya' });

		return (
			<Layout className="about-us-wrapper">
				<SEO
					title={`File Room`}
					description={`This page describes about our cloud storage. ${Math.random()}`}
				/>
				<Helmet>
					<link rel="canonical" href="/fileroom" />
				</Helmet>
				<div style={{ backgroundColor: '#606e7d' }}>
					<div className="file-room-wrapper">
						<div className="fileroom-logo" />
						<h2 className="welcome-text">Welcome to the file room</h2>
						{/* <div className="whitlock-logo" /> */}
						<div className="form">
							<TextBox
								name={'userName'}
								value={userName}
								onChange={this.onChange}
								placeholder={'Enter your email'}
								label={'Email'}
								type={'email'}
								className="text-input"
							/>
							<TextBox
								name={'password'}
								value={password}
								onChange={this.onChange}
								placeholder={'Enter your password'}
								label={'Password'}
								type={'password'}
								className="text-input"
							/>
							<div className="button-row d-flex d-vh-center pt-20">
								<button className="login-button" onClick={(e) => this.onSubmit(e)}>
									Login
								</button>
								<button className="forgotpassword-button">Forgot password?</button>
							</div>

							<GoogleLogin
								clientId="617185235931-cr8vmdeqhgmo8f0vb78hve07ojnu5jm0.apps.googleusercontent.com"
								buttonText="Login"
								onSuccess={this.responseGoogle}
								onFailure={this.responseGoogle}
								cookiePolicy={'single_host_origin'}
								className="google-login"
							/>
						</div>
					</div>
				</div>{' '}
			</Layout>
		);
	}
}

export default FileRoom;
